import React from 'react'
import Layout from '../../components/layout'
import CTA from '../../components/cta'
import CheckList from '../../icons/checklist.svg'
import LayoutWireframe from '../../icons/layout-wireframe.svg'
import Migrate from '../../icons/migrate.svg'
import LaptopMoney from '../../icons/laptop-money.svg'
import InfoContactUsForm from '../../components/info-contact-us-form'
import {navigate} from "gatsby";

const subscriberMagento1SupportHasEnded = process.env.GATSBY_SUBSCRIBER_LP_MAGENTO_1_SUPPORT_HAS_ENDED_FORM_TAG;

const Magento1SupportHasEnded = ({location}) => {
    return (
        <Layout>
            <section>
                <div className="bg-dark d-flex flex-column align-items-center pt-6">
                    <h3 className="text-secondary-light">It's Official</h3>
                    <h1 className="text-white">Support for Magento v1 version ended as of June 2020!</h1>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row py-6">
                        <div className="col-6">
                            <h2>729 can help you make the move quickly.</h2>
                        </div>

                        <div className="col-6">
                            <p>
                                Support for ALL Magento 1 versions in all their forms — Community, Open Source, Enterprise and Commerce — ended as of <strong>June 2020!</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark">
                <div className="container py-6">
                    <div className="row">
                        <div className="col-6">
                            <h2 className="text-white">You're out of time!</h2>
                            <p className="text-white">
                                Unfortunately Magento 1 to Magento 2 migration is a complex project and takes effective planning and quality implementation.
                                <br /><br />
                                So if your online store is running on Magento 1 it’s time to prepare for Magento 2 migration right now.
                            </p>
                        </div>

                        <div className="col-6">
                            <h2 className="text-white">729 Can Help You:</h2>
                            <ul className="text-white">
                                <li>Just migrate your basic site quickly</li>
                                <li>Update design and user workflows- it’s a great time to make your site better!</li>
                                <li>Make sure your ecommerce integrations, connectors and apps still work in Magento 2 (Magento 2 has a different API from version 1)</li>
                                <li>Implement monitoring systems if needed.</li>
                                <li>Help you go live and test your site for performance</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row pt-6">
                        <div className="col d-flex justify-content-center">
                            <CTA href="/contact-us" text="GET HELP WITH A MIGRATION NOW" className="btn btn-secondary-light" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-6 bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <h2 className="text-white">How does it work?</h2>
                            <CheckList
                                className="flex-shrink-0 text-white border border-white mb-4"
                                width={50}
                            />
                            <p className="text-white">
                                Step 1 - Audit your current Magento site including selecting the right Magento version to move to and investigating your current custom code and connectors.
                            </p>

                            <LayoutWireframe
                                className="flex-shrink-0 text-white mb-4"
                                width={50}
                            />
                            <p className="text-white">
                                Step 2 - Evaluate Design and Development needs to move to Magento 2
                            </p>

                            <LaptopMoney
                                className="flex-shrink-0 text-white mb-4"
                                width={50}
                            />
                            <p className="text-white">
                                Step 3 - 729 will provide you a time and price quote
                            </p>

                            <Migrate
                                className="flex-shrink-0 text-white mb-4"
                                width={50}
                            />
                            <p className="text-white">
                                Step 4 - Begin work and execute on migration ASAP!
                            </p>
                        </div>

                        <div className="col-5">
                            <h3 className="text-white">Learn how we helped SFMOMA migrate to Magento 2</h3>
                            <div className="container bg-white py-3">
                                <InfoContactUsForm
                                  location={location}
                                  tags={[subscriberMagento1SupportHasEnded]}
                                  onSubmitSuccess={()=> navigate('/lp/magento-2-migration-download')}
                                  buttonText="GET THE DOWNLOAD" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h1>What clients are saying about us</h1>
                        </div>

                        <div className="col-6">
                            <figure className="quotebox">
                                <blockquote className="blockquote">
                                    Thanks for all your hard work on the M2 migration… The webstore ran SUPER fast on our busiest day ever!
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    Kevin Convertito - <cite className="text-success" title="Source Title">SFMOMA</cite>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-6 bg-dark">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <CTA href="/contact-us" text="GET HELP WITH A MIGRATION NOW" className="btn btn-secondary-light" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Magento1SupportHasEnded
